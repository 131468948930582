@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  min-height: 100svh;
}

*{
  box-shadow: box-sizing;
  font-family: Manrope, sans-serif;
}

.App{
  min-height: 100svh;
}