

.card{
    border-radius: 25px;
    width: 300px;
    height: 150px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
}

.card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    position: absolute;

}

.black{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.card span{
    z-index: 2;
}


@media screen and (min-width: 1560px) {
    .card{
        width: 400px;
        height: 200px;
        font-size: 2rem;
    }
}


@media screen and (min-width: 1024px) and (max-width: 1440px){
    .card{
        width: 250px;
        height: 125px;
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .card{
        width: 215px;
        height: 110px;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 767.5px) {
    .card{
        width: 165px;
        height: 100px;
        font-size: 1rem;
    }
}