
main{
    margin: 0;
    padding: 0;
    height: 100%;
    max-height: 100%;
    border-box: box-sizing;
}

.container{
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100svh;
    width: 100%;
    background-position: cover;
}

.home{
    height: 100%;
    background: rgba(244,249,249,0.6);
    overflow: hidden;
    min-height: 100svh;
}

header{
   display: flex;
   justify-content: center;
}

.home_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background-color: rgba(255,255,255,.8);
    padding: 1rem 1.25rem;
    background-filter: blur(5px);
    border-radius: 0px 0px 20px 20px;
}

.home_header_info{
    display: flex;
    align-items: center;
    gap: 25px;
}

.phone_container{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3c9bb9;
    transition: 0.3s all;
    text-decoration: none;
    cursor: pointer;
}

.phone_container:hover{
    color: #32b9e2;
}

.phone{
   color: #3c9bb9;
   display: flex;
}

.phone svg{
    width: 20px;
    height: 20px;
}

.wrapper{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 40px;
    flex-direction: column;
    margin-top: 30px;
}
.wrapper_bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.wrapper_center{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.main_text{
    font-weight: bold;
    color: black;
    text-align: center;
    max-width: 300px;
    gap: 20px;
    border-radius: 25px;
    background-color: rgba(255,255,255,.9);
    padding: 25px

}

h1{
    font-size: 1.6rem;
    text-transform: uppercase;
    margin: 0;
}

.main_text span{
  font-size: 0.9rem;
  color: #495d63;
  font-weight: 500;
}

.logo{
    font-family: "Roboto Slab", serif;
    font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .wrapper{
        gap: 20px;
    }
    .wrapper_bottom{
        gap: 20px;
    }
    .wrapper_center{
        gap: 20px;
    }
    .main_text{
        max-width: 225px;
    }
    h1{
        font-size: 1.1rem;
        text-transform: uppercase;
        margin: 0;
    }
    .main_text span{
        font-size: 0.8rem;
    }
}

footer{
    display: none;
}


@media screen and (max-width: 767.5px) {
    .home{
       display: flex;
       flex-direction: column;
       justify-content: space-between;
    }
    header{
        display: none;
    }
    .home_header{
        justify-content: center;
    }
    footer{
        display: flex;
    }
    main{
        height: 85svh;
    }
    .wrapper{
        gap:20px;
        padding-bottom: 20px;
        height: 100%;
        justify-content: center;
    }
    .home_header_info{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .home_header{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: rgba(255,255,255,.8);
        padding: 1rem 1.25rem;
        background-filter: blur(5px);
        border-radius: 0px;
    }
}